"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Profile card — https://app.subframe.com/feb895689ba0/library?component=Profile+card_77925348-b362-494a-be7e-c915d2486724
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";

interface ProfileCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  text?: string;
  text2?: string;
  text3?: string;
  selected?: boolean;
  className?: string;
}

const ProfileCardRoot = React.forwardRef<HTMLElement, ProfileCardRootProps>(
  function ProfileCardRoot(
    {
      image,
      text,
      text2,
      text3,
      selected = false,
      className,
      ...otherProps
    }: ProfileCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/77925348 flex cursor-pointer flex-col items-start justify-center gap-4 rounded-lg border border-solid border-neutral-100 bg-white px-4 py-4 hover:border hover:border-solid hover:border-neutral-300 hover:shadow-lg active:border active:border-solid active:border-neutral-700 active:bg-millet-500",
          { "border border-solid border-neutral-700 bg-millet-500": selected },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <Avatar size={80} src={image!} name={text!} type="shape" />
        <div className="flex w-full flex-col items-start justify-center gap-1">
          {text ? (
            <span className="text-[18px] font-[500] leading-[28px] text-default-font">
              {text}
            </span>
          ) : null}
          {text2 ? (
            <span className="w-full text-[14px] font-[400] leading-[20px] text-neutral-500">
              {text2}
            </span>
          ) : null}
          {text3 ? (
            <span className="w-full text-[14px] font-[400] leading-[20px] text-neutral-500">
              {text3}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);

export const ProfileCard = ProfileCardRoot;
