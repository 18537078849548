import * as React from "react";
import AsyncSelect from "react-select/async";
import { useAccountSearchBar } from "./useAccountSearchBar";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CustomAccountOption from "./components/customAccountOption";

export interface AccountSearchBarProps {}

export const AccountSearchBar: React.FC<AccountSearchBarProps> = (props) => {
  const { handleAccountSearch, navigate } = useAccountSearchBar();
  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={handleAccountSearch as any}
        onChange={(event: any) => {
          navigate(event.bookingLink);
        }}
        className="text-zinc-500 text-sm !outline-none mobile:text-[16px]"
        noOptionsMessage={(event) =>
          event.inputValue.length > 2
            ? "No results found"
            : "Type to search (min 3 characters)"
        }
        placeholder={
          <div className="flex items-center gap-2">
            <MagnifyingGlassIcon className="w-4 h-4" />
            <p className="font-semibold mobile:font-normal">Find your store</p>
          </div>
        }
        components={{
          Option: CustomAccountOption,
          IndicatorsContainer: () => null,
        }}
      />
    </>
  );
};
