"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * AsInput — https://app.subframe.com/feb895689ba0/library?component=AsInput_f45dfa71-d2b1-4826-bd47-2993766763a3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface AsInputRootProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leading?: string;
  error?: boolean;
  trailing?: string;
  disabled?: boolean;
  showLeadingIcon?: boolean;
  placeholder?: string;
  leadingIcon?: SubframeCore.IconName;
  className?: string;
  trailingComponent?: any;
  textPosition?: "left" | "right";
}

const AsInputRoot = React.forwardRef<HTMLInputElement, AsInputRootProps>(
  function AsInputRoot(
    {
      leading,
      trailing,
      trailingComponent,
      error = false,
      disabled = false,
      placeholder,
      textPosition = "right",
      showLeadingIcon = false,
      leadingIcon = "FeatherPlus",
      className,
      ...otherProps
    }: AsInputRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/f45dfa71 flex h-12 overflow-hidden w-full items-center gap-1 rounded-md border border-solid border-neutral-50 bg-white hover:border hover:border-solid hover:border-neutral-200 active:border active:border-solid active:border-neutral-500 focus-within:border focus-within:border-solid focus-within:border-neutral-500",
          {
            "border border-solid border-neutral-400 bg-neutral-200": disabled,
            "border border-solid border-error-500": error,
          },
          className
        )}
      >
        {/* Leading Icon */}
        {showLeadingIcon && (
          <SubframeCore.Icon
            className={SubframeCore.twClassNames(
              "text-body font-body text-neutral-900",
              { "text-neutral-400": disabled }
            )}
            name={leadingIcon}
          />
        )}

        {/* Leading Text */}
        {leading && (
          <span
            className={SubframeCore.twClassNames(
              "text-[14px] font-[500] leading-[20px] text-neutral-500 group-active/f45dfa71:text-neutral-700 group-focus-within/f45dfa71:text-neutral-700",
              { "text-neutral-400": disabled }
            )}
          >
            {leading}
          </span>
        )}

        {/* Input Element */}
        <input
          className={SubframeCore.twClassNames(
            "flex-grow w-full bg-transparent px-3 h-full text-body font-body outline-none text-right text-[14px] font-[500] leading-[20px] mobile:text-[16px] text-neutral-700 group-active/f45dfa71:text-neutral-700 group-focus-within/f45dfa71:text-neutral-700 placeholder:text-neutral-500",
            {
              "text-neutral-400": disabled,
              "text-left": textPosition === "left",
              "text-right": textPosition === "right",
            }
          )}
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          {...otherProps} // Spread input HTML attributes here
        />

        {/* Trailing Text */}
        {trailing && (
          <span
            className={SubframeCore.twClassNames(
              "text-[14px] font-[400] leading-[20px] text-neutral-500 text-right",
              { "text-neutral-400": disabled }
            )}
          >
            {trailing}
          </span>
        )}

        {trailingComponent && (
          <div className="-mr-3"> {trailingComponent} </div>
        )}
      </div>
    );
  }
);

export const AsInput = AsInputRoot;
