import * as React from "react";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import {
  deleteAppointmentGroupByBookingToken,
  getAppointmentGroupByBookingToken,
} from "app/redux/appointmentGroupSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Path } from "app/path";
import { useQuery } from "app/utils/useQuery";

export const useManageBooking = () => {
  const dispatch = useDispatch();
  const { nameKey, token } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const account = useSelector(selectAccount);
  const [loading, setLoading] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [appointmentGroup, setAppointmentGroup] = useState<any>(null);
  const [isAppointmentsExpanded, setIsAppointmentsExpanded] = useState(false);
  const [cancelingAppointments, setCancelingAppointments] = useState(false);

  const getAppointmentGroup = async () => {
    try {
      const fetchedAppointmentGroup = await dispatch(
        getAppointmentGroupByBookingToken({ bookingToken: token! }) as any
      ).unwrap();
      setAppointmentGroup(fetchedAppointmentGroup);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const onAppointmentReschedule = () => {
    query.set("reschedule", "true");
    query.set("appointmentGroupToReschedule", token!);
    navigate(`/book/${nameKey}?${query.toString()}`);
  };

  const cancelAppointment = async () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to cancel this appointment?"
    );

    if (!shouldDelete) {
      return;
    }

    try {
      setCancelingAppointments(true);
      await dispatch(
        deleteAppointmentGroupByBookingToken({ bookingToken: token! }) as any
      ).unwrap();
      const canceledLink = generatePath(Path.CANCELED_BOOKING, {
        nameKey,
      } as any);
      navigate(canceledLink);
    } catch (error) {
      console.log("error", error);
    } finally {
      setCancelingAppointments(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!token) {
        setIsTokenInvalid(true);
        return;
      }
      if (!nameKey) {
        toast.error("Invalid URL. Please try again.");
        navigate("/");
        return;
      }
      await dispatch(getAccount({ nameKey }) as any);
      getAppointmentGroup();
    })();
  }, []);

  return {
    isTokenInvalid,
    loading,
    appointmentGroup,
    isAppointmentsExpanded,
    setIsAppointmentsExpanded,
    account,
    cancelAppointment,
    cancelingAppointments,
    onAppointmentReschedule,
  };
};
