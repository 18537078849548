import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface ServicesSliceState {
  id: number;
  createdAt: string;
  updatedAt: string;
}

const initialState = [] as ServicesSliceState[];

export const getServiceCategories = createAsyncThunk(
  "services/getServiceCategories",
  async (shopKey: string) => {
    const { data } = await http.get("/v1/service_categories/public_index", {
      params: {
        shopKey,
        snakeCase: true,
      },
    });
    return data;
  }
);

// export const getServices = createAsyncThunk(
//   "services/getServices",
//   async (shopKey: string) => {

//   }
// );

// export const createService = createAsyncThunk(
// );

// export const updateService = createAsyncThunk(
// );

// export const deleteService = createAsyncThunk(
// );

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add cases for async thunks here
  },
});

export const selectServices = (state: RootState) => state.services;
