import { getAccount, selectAccount } from "app/redux/accountSlice";
import { getCart, selectCart } from "app/redux/cartSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { useQuery } from "app/utils/useQuery";
import { addMinutes, format } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useOrderComplete = () => {
  const { nameKey } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  const paymentMethod = query.get("payment");
  const account = useSelector(selectAccount);
  const cart = useSelector(selectCart);
  const [appointmentStartTimes, setAppointmentStartTimes] = React.useState<any>(
    []
  );

  const init = async () => {
    const cartUid = query.get("cart");
    if (!cartUid) return;

    try {
      await dispatch(getAccount({ nameKey } as any) as any).unwrap();
    } catch (error) {
      console.error(error);
    }

    try {
      const fetchedCart = await dispatch(
        getCart({ uid: cartUid }) as any
      ).unwrap();

      const startDate = fetchedCart!.startTime.split("T")[0];
      let currentTime = fetchedCart!.startTime.split("T")[1];
      const times = [];
      for (const service of fetchedCart.merchantServices) {
        const duration = service.duration;

        // Preserve the UTC time and convert to local
        const startTime = `${startDate}T${currentTime}`;

        // Format the start time to avoid leading zeros (if desired)
        times.push(format(preserveUtcTimeToLocal(startTime), "h:mma")); // 'H' for no leading zero

        // Add the service duration to get the end time
        const serviceEndTime = addMinutes(startTime, duration);
        currentTime = format(serviceEndTime, "HH:mm"); // Maintain HH:mm for internal logic
      }
      setAppointmentStartTimes(times);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    account,
    cart,
    appointmentStartTimes,
    paymentMethod,
  };
};
