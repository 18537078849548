import { Path } from "app/path";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { getAppointmentGroupByBookingToken } from "app/redux/appointmentGroupSlice";
import {
  CartSliceState,
  createCart,
  getCart,
  selectCart,
  updateCart,
} from "app/redux/cartSlice";
import { titleize } from "app/utils/string";
import { useQuery } from "app/utils/useQuery";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { find, findIndex, get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

interface Availability {
  id: number;
  day: string;
  timeStart: string;
  timeEnd: string;
  availabilityableType: string;
  availabilityableId: number;
  createdAt: string;
  updatedAt: string;
}

const getServiceProviders = ({ account, shopKey, serviceId }: any) =>
  find(account.shops, (sp) => sp.key === shopKey).serviceProviders.filter(
    (sp: any) => {
      const spBusinessServices = sp.services.map(
        (s: any) => s.businessService.id
      );
      return (
        findIndex(
          spBusinessServices,
          (id: number) => id === Number(serviceId)
        ) !== -1
      );
    }
  );

export const useBookStaff = () => {
  const { nameKey, shopKey, serviceId } = useParams();
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const account = useSelector(selectAccount);
  const [isLoading, setIsLoading] = useState(false);
  const shop = find(account.shops, { key: shopKey });
  const timezone = cart?.shop?.timezone;
  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<any>(null);
  const [serviceProviders, setServiceProviders] = useState([]);
  const currentShopTime = useMemo(() => {
    return timezone ? toZonedTime(new Date(), timezone.timeZoneId) : new Date();
  }, [timezone]);
  const [appointmentGroupToReschedule, setAppointmentGroupToReschedule] =
    useState<any>(null);

  const getNextAvailabilityDay = (availabilities: Availability[]): string => {
    const now = currentShopTime; // Assume currentShopTime is already in the shop's timezone
    const currentDay = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const currentTime = now.getTime();

    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    // Create a new array for sorting to avoid modifying the original array
    const sortedAvailabilities = [...availabilities].sort((a, b) => {
      if (a.day === b.day) {
        return (
          new Date(`1970-01-01T${a.timeStart}`).getTime() -
          new Date(`1970-01-01T${b.timeStart}`).getTime()
        );
      }
      return daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day);
    });

    let result = "No upcoming availabilities"; // Default message if no time is found

    // Iterate over days of the week starting from today
    for (let i = 0; i < 7; i++) {
      const dayToCheck = (currentDay + i) % 7;
      const dayName = daysOfWeek[dayToCheck];

      // Check all availabilities for the current day to check

      for (const availability of sortedAvailabilities) {
        if (availability.day === dayName) {
          // Convert availability time to shop's timezone
          const startTime = toZonedTime(
            `${format(now, "yyyy-MM-dd")}T${availability.timeStart}`,
            shop.timezone.timeZoneId
          );

          const endTime = toZonedTime(
            `${format(now, "yyyy-MM-dd")}T${availability.timeEnd}`,
            shop.timezone.timeZoneId
          );

          // Check if the availability is valid
          if (i === 0 && currentTime < endTime.getTime()) {
            result =
              currentTime < startTime.getTime()
                ? `Today at ${format(startTime, "h:mma")}`
                : `Today at ${format(endTime, "h:mma")}`;
            break;
          } else if (i > 0) {
            result = `${titleize(dayName)} at ${format(startTime, "h:mma")}`;
            break;
          }
        }
      }

      // Break out of the outer loop once a valid availability is found
      if (result !== "No upcoming availabilities") {
        break;
      }
    }

    return result;
  };

  const navigateToTime = () => {
    const timeUrl = generatePath(Path.BOOK_TIME, {
      nameKey,
      shopKey,
    } as any);
    navigate(`${timeUrl}?${query.toString()}`);
  };

  const navigateToService = () => {
    const serviceUrl = generatePath(Path.BOOK_SERVICE, {
      nameKey,
      shopKey,
    } as any);

    // Keep the cart parameter
    navigate(`${serviceUrl}?${query.toString()}`);
  };

  const onServiceProviderClick = async (sp: any) => {
    // create cart
    const merchantService = find(
      sp.services,
      (s) => s.businessService.id === Number(serviceId)
    );
    const cartParams = query.get("cart");
    try {
      if (!cart || !cartParams) {
        const cart = await dispatch(
          createCart({
            merchantServiceIds: [merchantService.id],
            shopId: shop.id,
          }) as any
        ).unwrap();
        query.set("cart", cart.uid);
        navigate(location.pathname + "?" + query.toString());
      } else {
        const updatedCart = await dispatch(
          updateCart({
            merchantServiceIds: [
              ...cart.merchantServices.map((ms) => ms.id),
              merchantService.id,
            ],
            shopId: shop.id,
            uid: cart.uid,
          }) as any
        ).unwrap();
      }
      setSelectedServiceProvider(sp);
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    if (!nameKey) return;
    setIsLoading(true);

    if (serviceId === "null") {
      setSelectedServiceProvider({});
    }

    if (query.get("appointmentGroupToReschedule")) {
      try {
        const appointmentGroup = await dispatch(
          getAppointmentGroupByBookingToken({
            bookingToken: query.get("appointmentGroupToReschedule"),
          } as any) as any
        ).unwrap();

        if (appointmentGroup) {
          setAppointmentGroupToReschedule(appointmentGroup);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    }

    try {
      const account = await dispatch(getAccount({ nameKey }) as any).unwrap();
      setServiceProviders(getServiceProviders({ account, shopKey, serviceId }));
      const cartParam = query.get("cart");

      if (cartParam === "null") {
        query.delete("cart");

        return navigate(account.bookingLink);
      }

      if (cartParam && cartParam !== "null") {
        await dispatch(getCart({ uid: cartParam as any }) as any).unwrap();
      }

      if (account.shops) {
        setServiceProviders(
          getServiceProviders({ account, shopKey, serviceId })
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    isLoading,
    serviceProviders,
    getNextAvailabilityDay,
    account,
    selectedServiceProvider,
    onServiceProviderClick,
    cart,
    navigateToService,
    navigateToTime,
    appointmentGroupToReschedule,
  };
};
