import * as React from "react";
import { useBookStaff } from "./useBookStaff";
import { FadeInSlide } from "app/motions/fadeInSlide";
import classNames from "classnames";
import { Loading } from "app/components/loading/loading";
import {
  AsButton,
  BreadcrumbChip,
  Header,
  LineItems,
  PersonInfo,
  ProfileCard,
} from "app/subframe";
import { Icon } from "@subframe/core";
import { getFormatedDuration } from "app/book/bookService/useBookService";
import { GenericSkeleton } from "app/components/skeleton/genericSkeleton";
import { truncate } from "lodash";
import { format } from "date-fns";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { MetaDescriptor } from "app/metaDescriptor/metaDescriptor";

export interface BookStaffProps {}

export const BookStaff: React.FC<BookStaffProps> = (props) => {
  const {} = props;
  const {
    serviceProviders,
    isLoading,
    getNextAvailabilityDay,
    account,
    selectedServiceProvider,
    onServiceProviderClick,
    cart,
    navigateToService,
    navigateToTime,
    appointmentGroupToReschedule,
  } = useBookStaff();

  if (isLoading) {
    return (
      <div className="pt-2 pb-4">
        <GenericSkeleton />
      </div>
    );
  }

  return (
    <div className="flex h-[95dvh] my-4 pb-4 grow shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
      <MetaDescriptor
        title={account.name}
        description={account.description}
        link={window.location.href}
      />
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account.logo}
        text={account.name}
        rescheduleFromStartTime={
          appointmentGroupToReschedule &&
          format(
            preserveUtcTimeToLocal(appointmentGroupToReschedule?.startTime),
            "MMM do 'at' h:mm a"
          )
        }
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center justify-between">
          <BreadcrumbChip text="Service" onClick={() => navigateToService()} />
          <Icon
            className="text-body font-body text-default-font"
            name="FeatherChevronRight"
          />
          <BreadcrumbChip text="Staff" selected={true} />
          <Icon
            className="text-body font-body text-default-font"
            name="FeatherChevronRight"
          />
          <BreadcrumbChip
            text="Time"
            onClick={() => {
              navigateToTime();
            }}
            disabled={
              !cart.merchantServices || cart.merchantServices.length === 0
            }
          />
        </div>
        {selectedServiceProvider && cart ? (
          <>
            {/* basically show cart */}
            <div className="flex w-full flex-col items-end justify-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
              <span className="w-full text-[18px] font-[500] leading-[28px] text-neutral-900">
                Your service
              </span>
              {cart.merchantServices.map((service: any, index: any) => (
                <>
                  <PersonInfo
                    className="h-auto w-full flex-none"
                    name={service.employee.name}
                    subtitle={service.title}
                    avatarImage={service.employee.avatar}
                  />
                  <LineItems
                    title="Duration"
                    value={getFormatedDuration(service)}
                  />
                  <div
                    className={classNames(
                      "flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200 mobile:bg-neutral-100",
                      {
                        hidden: index === cart.merchantServices.length - 1,
                      }
                    )}
                  />
                </>
              ))}
            </div>
            <div className="flex w-full flex-col items-center gap-4 py-6 ">
              <div className="flex w-full flex-col items-center gap-2">
                <span className="w-full text-body-bold font-body-bold text-neutral-900 text-center">
                  Do you want to add another service?
                </span>
              </div>
              <div className="flex w-full items-center gap-4">
                <AsButton
                  className="h-14 grow shrink-0 basis-0"
                  variant="secondary"
                  text="Yes"
                  size="lg"
                  loader={false}
                  onClick={() => navigateToService()}
                />
                <AsButton
                  className="h-14 grow shrink-0 basis-0"
                  variant="secondary"
                  leadingIcon={null}
                  trailingIcon={null}
                  text="No"
                  size="lg"
                  loader={false}
                  onClick={() => navigateToTime()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="grid grid-cols-2 w-full flex-wrap items-center gap-4 mobile:flex-row mobile:gap-4">
            {serviceProviders?.map((sp: any, index: number) => {
              const firstName = sp.name.split(" ")?.[0] || sp.name;
              const lastNameFirstLetter = sp.name.split(" ")?.[1]?.[0] || "";
              const nextAvailabilityDay = getNextAvailabilityDay(
                sp.availabilities
              );

              const [day, time] = nextAvailabilityDay.split(" at ");
              return (
                <FadeInSlide key={sp.id} direction="top">
                  <ProfileCard
                    className="h-auto grow shrink-0 basis-0"
                    image={sp.avatar}
                    text={truncate(`${firstName} ${lastNameFirstLetter}`, {
                      length: 16,
                      omission: ".",
                    })}
                    text2={`Available ${day}`}
                    text3={`At ${time}`}
                    onClick={() => {
                      onServiceProviderClick(sp);
                    }}
                  />
                </FadeInSlide>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
