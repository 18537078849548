import { ModalTypes, PaymentMethods } from "app/order/useOrder";
import {
  AsButton,
  AsInput,
  LineItems,
  PaymentMethodCta,
  PaymentModalHeader,
  PoweredBy,
} from "app/subframe";
import classNames from "classnames";
import { motion } from "framer-motion";
import * as React from "react";

export interface ConfirmBookingModalProps {
  setCurrentModal: (modal: ModalTypes) => void;
  taxCents: number;
  tipsCents: number;
  subtotalCents: number;
  paymentMethod: PaymentMethods | null;
  setPaymentMethod: (paymentMethod: PaymentMethods) => void;
  onBook: (event: any) => void;
  bookingPayload: any;
  appointmentGroupToReschedule?: any;
  isCreditCardPaymentModalOpen: boolean;
  isPreAuthModalOpen: boolean;
}

export const ConfirmBookingModal: React.FC<ConfirmBookingModalProps> = ({
  setCurrentModal,
  taxCents,
  tipsCents,
  subtotalCents,
  paymentMethod,
  setPaymentMethod,
  onBook,
  bookingPayload = {},
  appointmentGroupToReschedule,
  isCreditCardPaymentModalOpen,
  isPreAuthModalOpen,
}) => {
  const clientToBeRescheduled =
    appointmentGroupToReschedule?.appointments?.[0].client;
  return (
    <form
      onSubmit={onBook}
      className={classNames(
        "flex rounded-lg w-112 flex-col items-start gap-4 bg-default-background mobile:w-full mobile:items-center mobile:justify-start mobile:bg-[#fcfcfdff] mobile:px-0 mobile:py-0 overflow-scroll scrollbar-hide",
        {
          "h-[92dvh]": !isCreditCardPaymentModalOpen && !isPreAuthModalOpen,
        }
      )}
    >
      <div className="bg-neutral-300/80 absolute left-0 bottom-0 h-screen w-112 rounded-b-lg mobile:w-full -z-10" />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center justify-end gap-2 bg-neutral-200 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-end mobile:bg-[#171718cc] mobile:px-0 mobile:py-0">
        <motion.div
          initial={{ y: 800, opacity: 1 }} // Start off-screen if animating
          animate={{ y: 0, opacity: 1 }} // Slide up and fade in if animating
          transition={{ duration: 0.2, ease: "easeInOut" }} // Animation settings
          className="rounded-t-lg w-112 mobile:w-full"
        >
          <div className="relative flex w-full px-2 flex-col items-center gap-6 rounded-t-lg bg-white pb-5 shadow-pop-up-shadow mobile:w-full mobile:flex-none">
            <div className="flex flex-col items-center pt-4 gap-4 top-0 sticky bg-white left-0 w-full z-10">
              <PaymentModalHeader
                text="Confirm booking"
                buttonProps={{
                  onClick: () => setCurrentModal(ModalTypes.INITIAL),
                }}
              />
              <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-50" />
            </div>
            <span className="w-full text-body-bold font-body-bold text-neutral-900">
              Your information
            </span>
            <div className="flex w-full items-center gap-4">
              <AsInput
                textPosition="left"
                placeholder="First name"
                error={false}
                showLeadingIcon={false}
                disabled={false}
                name="firstName"
                type="text"
                defaultValue={
                  bookingPayload?.firstName ||
                  clientToBeRescheduled?.name.split(" ")[0]
                }
                required
              />
              <AsInput
                textPosition="left"
                placeholder="Last name"
                error={false}
                showLeadingIcon={false}
                disabled={false}
                name="lastName"
                type="text"
                defaultValue={
                  bookingPayload?.lastName ||
                  clientToBeRescheduled?.name.split(" ")[1]
                }
                required
              />
            </div>
            <div className="flex w-full items-center gap-4">
              <AsInput
                textPosition="left"
                placeholder="Phone number"
                error={false}
                showLeadingIcon={false}
                disabled={false}
                name="phone"
                type="tel"
                defaultValue={
                  bookingPayload?.phone || clientToBeRescheduled?.phone
                }
                required
              />
            </div>
            <div className="flex w-full items-center gap-4">
              <AsInput
                textPosition="left"
                placeholder="Email"
                error={false}
                showLeadingIcon={false}
                disabled={false}
                name="email"
                type="email"
                defaultValue={
                  bookingPayload?.email || clientToBeRescheduled?.email
                }
                required
              />
            </div>
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 border border-dashed border-neutral-50" />
            <span className="w-full text-body-bold font-body-bold text-neutral-900">
              Payment method
            </span>
            <PaymentMethodCta
              icon="FeatherCreditCard"
              text="Credit Card"
              selected={paymentMethod === PaymentMethods.CREDIT_CARD}
              onClick={() => setPaymentMethod(PaymentMethods.CREDIT_CARD)}
            />
            <PaymentMethodCta
              icon="FeatherStore"
              text="Pay in person"
              selected={paymentMethod === PaymentMethods.PAY_IN_PERSON}
              onClick={() => setPaymentMethod(PaymentMethods.PAY_IN_PERSON)}
            />
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 border border-dashed border-neutral-50" />
            <span className="w-full text-body-bold font-body-bold text-neutral-900">
              Summary
            </span>
            <LineItems
              title="Subtotal"
              value={`$${(subtotalCents / 100).toFixed(2)}`}
              additionalInfo=""
            />
            <LineItems
              title="Tax"
              value={`$${(taxCents / 100).toFixed(2)}`}
              additionalInfo=""
            />
            <LineItems
              title="Tip"
              value={`$${(tipsCents / 100).toFixed(2)}`}
              additionalInfo=""
            />
            <LineItems
              title="Total"
              value={`$${((tipsCents + subtotalCents + taxCents) / 100).toFixed(
                2
              )}`}
              additionalInfo=""
            />
            <div className="flex w-full flex-col items-center gap-4">
              <AsButton
                className="h-14 w-full flex-none"
                variant="default"
                leadingIcon={null}
                trailingIcon={null}
                text="Book"
                size="lg"
                loader={false}
                disabled={false}
                disabledSecondary={false}
                type="submit"
              />
              <span className="text-[14px] font-[400] leading-[20px] text-neutral-500 text-center">
                By booking an appointment you agree to the Terms of Service and
                the Privacy Policy.
              </span>
              <PoweredBy text="Powered by" />
            </div>
          </div>
        </motion.div>
      </div>
    </form>
  );
};
