"use client";
/*
 * Documentation:
 * Header — https://app.subframe.com/feb895689ba0/library?component=Header_d4b8c553-766f-4398-94c7-9bab930ea389
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface HeaderRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  text?: React.ReactNode;
  variant?: "default";
  blur?: "default";
  rescheduleFromStartTime?: string;
  className?: string;
}

const HeaderRoot = React.forwardRef<HTMLElement, HeaderRootProps>(
  function HeaderRoot(
    {
      image,
      text,
      variant = "default",
      blur = "default",
      rescheduleFromStartTime,
      className,
      ...otherProps
    }: HeaderRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/d4b8c553 flex flex-col items-center bg-white",
          { "flex-col gap-0": rescheduleFromStartTime },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full items-center gap-4 px-2 py-4">
          {image ? (
            <img
              className="h-8 w-8 flex-none rounded-md object-cover"
              src={image}
            />
          ) : null}
          {text ? (
            <span className="text-body-bold font-body-bold text-default-font">
              {text}
            </span>
          ) : null}
        </div>
        <div
          className={SubframeCore.twClassNames(
            "hidden items-center gap-4 bg-millet-500 px-2 py-4",
            { "flex h-auto w-full flex-none": rescheduleFromStartTime }
          )}
        >
          <span className="grow shrink-0 basis-0 font-['Inter'] text-[16px] font-[400] leading-[24px] text-neutral-700">
            Rescheduling from:
          </span>
          <span className="text-body-bold font-body-bold text-neutral-900 text-right">
            {rescheduleFromStartTime}
          </span>
        </div>
      </div>
    );
  }
);

export const Header = HeaderRoot;
