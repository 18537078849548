import { CartSliceState } from "app/redux/cartSlice";
import { AsButton } from "app/subframe";
import * as React from "react";
import { getFormatedDuration } from "../useBookService";

export interface ExistingServicesProps {
  cart: CartSliceState;
  onRemoveMerchantService: (merchantService: any) => void;
  navigateToTime: () => void;
}

export const ExistingServices: React.FC<ExistingServicesProps> = ({
  cart,
  onRemoveMerchantService,
  navigateToTime,
}) => {
  return (
    <div className="flex w-full flex-col items-end justify-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
      <div className="flex w-full flex-col items-center gap-1">
        <span className="w-full text-body-bold font-body-bold text-neutral-700">
          Your services
        </span>
        <span className="w-full text-[14px] font-[400] leading-[20px] text-neutral-500">
          Add another service below or click Next to pick a date and time for
          your appointment.
        </span>
      </div>
      <div className="flex w-full flex-col items-start justify-center gap-1">
        {cart.merchantServices.map((service) => (
          <>
            <div className="flex w-full items-center gap-4">
              <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
                {service.employee.name}
              </span>
              <AsButton
                className="h-8 w-16 flex-none"
                variant="destructive-ghost"
                text="Remove"
                size="sm"
                loader={false}
                onClick={() => onRemoveMerchantService(service)}
              />
            </div>
            <div className="flex w-full items-end justify-center gap-4">
              <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-500">
                {service.title}
              </span>
              <span className="text-[14px] font-[500] leading-[20px] text-neutral-900">
                {getFormatedDuration(service)}
              </span>
            </div>
          </>
        ))}
      </div>
      <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
      <AsButton
        className="h-10 w-28 flex-none"
        variant="secondary"
        leadingIcon={null}
        trailingIcon={null}
        text="Next"
        size="default"
        onClick={() => navigateToTime()}
      />
    </div>
  );
};
