import * as React from "react";
import { useCanceledBooking } from "./useCanceledBooking";
import { AsButton, Header, RequestedPayment } from "app/subframe";

export interface CanceledBookingProps {}

export const CanceledBooking: React.FC<CanceledBookingProps> = ({}) => {
  const { onReschedulePress, account } = useCanceledBooking();

  return (
    <div className="flex h-[95dvh] my-4 pb-4 grow shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
      {" "}
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account?.logo}
        text={account?.name}
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-20 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <RequestedPayment
          text={`Your appointment with ${account?.name} has been cancelled.`}
          single={true}
        />
        <AsButton
          className="h-14 w-full flex-none"
          text="Reschedule appointment"
          size="lg"
          loader={false}
          disabled={false}
          disabledSecondary={false}
          onClick={() => onReschedulePress()}
        />
      </div>
    </div>
  );
};
