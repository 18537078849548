import { format, parse } from "date-fns";

const date = new Date();
const options: Intl.DateTimeFormatOptions = { month: "short" }; // Use 'short' for abbreviated month names
const monthNames = Array.from({ length: 12 }, (_, i) =>
  new Date(date.getFullYear(), i, 1).toLocaleString(undefined, options)
);

export const getFormatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const correctedDate = new Date(date.getTime() + userTimezoneOffset);

  const year = correctedDate.getFullYear();
  const month = (correctedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = correctedDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getFormatDate2 = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const getFormattedBeginingOfMonthToNow = () => {
  const formatDate = (date: any) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const getFormattedDateRange = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const formattedToday = formatDate(today);
    const formattedFirstDayOfMonth = formatDate(firstDayOfMonth);

    return `${formattedFirstDayOfMonth} to ${formattedToday}`;
  };

  return getFormattedDateRange();
};

export const getDaysBetweenTwoDates = (date1: any, date2: any) => {
  const startDate = new Date(date1) as any;
  const endDate = new Date(date2) as any;
  const timeDifference = endDate - startDate;
  const differenceInDays = Number(
    Math.ceil(timeDifference / -(1000 * 60 * 60 * 24)).toFixed(0)
  );
  return differenceInDays > 0 ? differenceInDays : 1;
};

export const getFormatTime = (dateString: string): string => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime = `${hours}:${minutesStr} ${ampm}`;
  return formattedTime;
};

export const convertTo12HourFormat = (timeStr: string) => {
  // Parse the time string to a Date object (using any date for the day, it won't affect the time)
  const parsedDate = parse(timeStr, "HH:mm", new Date());

  // Format the Date object to 12-hour format with am/pm
  return format(parsedDate, "h:mm a").toLowerCase(); // e.g. 2:00pm
};

export const preserveUtcTimeToLocal = (dateString: string): Date => {
  // Parse the input UTC date string
  const utcDate = new Date(dateString);

  // Extract the UTC components
  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth();
  const day = utcDate.getUTCDate();
  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();
  const milliseconds = utcDate.getUTCMilliseconds();

  // Create a new Date object using the extracted components, as if they were local
  const preservedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );

  return preservedDate;
};

export const formatAsUTC = (date: Date, dateFormat?: string) => {
  // Adjust the date to UTC by adding the timezone offset
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  if (!dateFormat) {
    return utcDate.toISOString();
  } else {
    return format(utcDate, dateFormat);
  }
};
