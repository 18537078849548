import { getAccount, selectAccount } from "app/redux/accountSlice";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const useCanceledBooking = () => {
  const dispatch = useDispatch();
  const { nameKey } = useParams();
  const account = useSelector(selectAccount);
  const navigate = useNavigate();
  const query = useQuery();

  const onReschedulePress = () => {
    query.set("reschedule", "true");
    navigate(`/book/${nameKey}?${query.toString()}`);
  };

  useEffect(() => {
    (async () => {
      if (!nameKey) {
        return navigate("/");
      }
      if (!account.id) {
        try {
          await dispatch(getAccount({ nameKey }) as any).unwrap();
        } catch (error) {
          navigate("/");
          console.log("error", error);
        }
      }
    })();
  }, []);

  return {
    account,
    onReschedulePress,
  };
};
