"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Person info — https://app.subframe.com/feb895689ba0/library?component=Person+info_c4a7b8ae-2a4b-43b9-8acc-30058838bc0d
 * Avatar — https://app.subframe.com/feb895689ba0/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";

interface PersonInfoRootProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  subtitle?: string;
  className?: string;
  avatarImage?: string;
}

const PersonInfoRoot = React.forwardRef<HTMLElement, PersonInfoRootProps>(
  function PersonInfoRoot(
    {
      name,
      subtitle,
      className,
      avatarImage,
      ...otherProps
    }: PersonInfoRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex items-center gap-4",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <Avatar size={56} src={avatarImage!} name={name!} />
        <div className="flex flex-col items-start justify-center gap-2">
          {name ? (
            <span className="text-body-bold font-body-bold text-neutral-700">
              {name}
            </span>
          ) : null}
          {subtitle ? (
            <span className="text-[14px] font-[400] leading-[20px] text-neutral-500">
              {subtitle}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);

export const PersonInfo = PersonInfoRoot;
