import * as React from "react";
import { motion } from "framer-motion";
import { useBook } from "./useBook";
import { Button, Image, Spinner } from "@nextui-org/react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import Map, { Marker } from "react-map-gl";
import classNames from "classnames";
import { Link, generatePath } from "react-router-dom";
import { Path } from "app/path";
import { FadeInSlide } from "app/motions/fadeInSlide";
import HairStudioPlaceHolderImg from "app/assets/images/hairStudioPlaceholder.jpg";
import LandingImagePlaceholderImg from "app/assets/images/landingImagePlaceholder.jpg";
import { Header, LocationCard } from "app/subframe";
import { Loading } from "app/components/loading/loading";
import { format } from "date-fns";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { MetaDescriptor } from "app/metaDescriptor/metaDescriptor";

export interface BookProps {}

export const Book: React.FC<BookProps> = (props) => {
  const {} = props;
  const {
    account,
    showLocations,
    onBookNowClick,
    shopHovered,
    setShopHovered,
    navigate,
    isLoading,
    query,
    appointmentGroupToReschedule,
  } = useBook();

  if (isLoading) {
    return <Loading type="dark" fullScreen />;
  }

  return (
    <div>
      <MetaDescriptor
        title={account.name}
        description={account.description}
        link={window.location.href}
      />
      {showLocations ? (
        <section className="flex justify-center">
          <FadeInSlide
            key="locations"
            direction="left"
            className="flex h-[95dvh] mt-4 grow shrink-0 basis-0 flex-col items-center rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0"
          >
            <Header
              className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mb-4"
              image={account.logo || HairStudioPlaceHolderImg}
              text={account.name}
              rescheduleFromStartTime={
                appointmentGroupToReschedule &&
                format(
                  preserveUtcTimeToLocal(
                    appointmentGroupToReschedule?.startTime
                  ),
                  "MMM do 'at' h:mm a"
                )
              }
            />

            <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
              <span className="w-full text-body font-body text-neutral-700">
                Choose a location
              </span>
              <div className="flex w-full flex-col items-center gap-2 mobile:flex-col mobile:gap-2">
                {account.shops?.map((shop) => {
                  const isComplete =
                    shop?.latitude && shop?.longitude && shop.addressLine1;
                  return (
                    <Link
                      to={
                        generatePath(Path.BOOK_SERVICE, {
                          nameKey: account.key,
                          shopKey: shop.key,
                        }) + `?${query.toString()}`
                      }
                      key={shop.id}
                      className="w-full"
                    >
                      <LocationCard
                        name={shop.name}
                        addressLine1={shop.addressLine1}
                        addressLine2={shop.addressLine2}
                      />
                    </Link>
                  );
                })}
              </div>
            </div>
          </FadeInSlide>
        </section>
      ) : (
        <section className="flex flex-col gap-4">
          <div className="flex flex-col gap-8 justify-center h-full max-w-[570px] p-10 absolute left-0 top-0 z-30 bg-white bg-opacity-80">
            <FadeInSlide
              key="locations"
              direction="left"
              className="flex flex-col gap-4"
            >
              <Image
                src={account.logo || HairStudioPlaceHolderImg}
                alt={`Logo of ${account.name}`}
                className="w-32 h-32"
              />
              <p className="text-4xl uppercase font-bold">{account.name}</p>
              <div className="flex flex-col gap-4">
                <p className="text-sm text-gray-500 font-light">
                  {account.description}
                </p>
                <div className="flex items-center gap-1 w-full">
                  <MapPinIcon className="h-4 w-4" />
                  <p className="text-sm text-gray-500 font-light">
                    {account.shops?.length} locations
                  </p>
                </div>
              </div>
              <Button
                className="bg-black rounded bg-opacity-95 mt-8"
                onClick={onBookNowClick}
              >
                <p className="text-xs text-white">Book now</p>
              </Button>
            </FadeInSlide>
          </div>
          <Image
            src={account.selectedLandingImageLink || LandingImagePlaceholderImg}
            alt="Barbershop"
            className="object-cover rounded-none h-screen w-screen"
          />
        </section>
      )}
    </div>
  );
};
