"use client";
/*
 * Documentation:
 * Service card — https://app.subframe.com/feb895689ba0/library?component=Service+card_dee8fa73-5fd6-4a44-9c07-02c6da4d6526
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface ServiceCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  text2?: string;
  text3?: string;
  className?: string;
}

const ServiceCardRoot = React.forwardRef<HTMLElement, ServiceCardRootProps>(
  function ServiceCardRoot(
    { text, text2, text3, className, ...otherProps }: ServiceCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/dee8fa73 flex w-full cursor-pointer items-start gap-2 border border-solid border-neutral-50 bg-white px-4 py-6 active:border active:border-solid active:border-neutral-700 active:bg-millet-300",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex grow shrink-0 basis-0 flex-col items-start">
          {text ? (
            <span className="text-body-bold font-body-bold text-neutral-700 group-hover/dee8fa73:text-green-400">
              {text}
            </span>
          ) : null}
          {text2 ? (
            <span className="text-[14px] font-[400] leading-[20px] text-neutral-500 group-hover/dee8fa73:text-green-400">
              {text2}
            </span>
          ) : null}
        </div>
        {text3 ? (
          <span className="text-body-bold font-body-bold text-neutral-900 group-hover/dee8fa73:text-green-400">
            {text3}
          </span>
        ) : null}
      </div>
    );
  }
);

export const ServiceCard = ServiceCardRoot;
