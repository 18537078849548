import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { RootState } from "./store";

export interface AppointmentsSliceState {
  id: number;
  uid: string;
  serviceProvider: any;
  status: string;
  services: any[];
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = [] as AppointmentsSliceState[];

export const createAppointment = createAsyncThunk(
  "appointments/createAppointmentStatus",
  async (
    {
      startTime,
      endTime,
      shopId,
      email,
      name,
      employeeId,
      serviceIds,
      phone,
      status,
      appointmentGroupId,
      companyId,
      cartUid,
    }: {
      startTime: string;
      endTime: string;
      shopId: number;
      email: string;
      name: string;
      status: string;
      employeeId: number;
      serviceIds: number[];
      userId?: number;
      phone?: any;
      companyId: number;
      appointmentGroupId?: number;
      cartUid: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/appointments`, {
        appointment: {
          startTime,
          endTime,
          shopId,
          email,
          name,
          status,
          phone,
          employeeId,
          serviceIds,
          appointmentGroupId,
          companyId,
          cartUid,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAppointment.fulfilled, (state, action) => {
      state.push(action.payload);
    });
  },
});

export const selectAppointments = (state: RootState) => state.appointments;
