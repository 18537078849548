import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useHome = () => {
  const query = useQuery();
  const nrdPathToRedirectTo = query.get("nrdPath");
  const navigate = useNavigate();

  useEffect(() => {
    if (nrdPathToRedirectTo) {
      navigate(nrdPathToRedirectTo);
    }
  }, []);

  return {
    nrdPathToRedirectTo,
  };
};
