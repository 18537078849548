"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Breadcrumb chip — https://app.subframe.com/feb895689ba0/library?component=Breadcrumb+chip_2c4e3266-a933-4656-9647-5e0d553f8904
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface BreadcrumbChipRootProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  selected?: boolean;
  className?: string;
  disabled?: boolean;
}

const BreadcrumbChipRoot = React.forwardRef<
  HTMLElement,
  BreadcrumbChipRootProps
>(function BreadcrumbChipRoot(
  {
    text,
    selected = false,
    className,
    disabled = false,
    ...otherProps
  }: BreadcrumbChipRootProps,
  ref
) {
  return (
    <button
      className={SubframeCore.twClassNames(
        "group/2c4e3266 flex w-24 cursor-pointer flex-col items-center justify-center gap-4 rounded-md border border-solid border-transparent px-4 py-1 hover:border hover:border-solid hover:border-neutral-300 active:border active:border-solid active:border-millet-500 active:bg-millet-500",
        {
          "border border-solid border-millet-500 bg-millet-500": selected,
          "opacity-50 !border-transparent !bg-transparent !cursor-default":
            disabled,
        },
        className
      )}
      ref={ref as any}
      disabled={disabled}
      {...otherProps}
    >
      {text ? (
        <span
          className={SubframeCore.twClassNames(
            "text-body font-body text-neutral-700 text-center group-active/2c4e3266:text-[16px] group-active/2c4e3266:font-[600] group-active/2c4e3266:leading-[24px] group-active/2c4e3266:text-neutral-900",
            {
              "text-[16px] font-[600] leading-[24px] text-neutral-900":
                selected,
              "!text-font-[400] !text-neutral-700 !text-body": disabled,
            }
          )}
        >
          {text}
        </span>
      ) : null}
    </button>
  );
});

export const BreadcrumbChip = BreadcrumbChipRoot;
