import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";

export const getAppointmentGroupByBookingToken = createAsyncThunk(
  "appointments/getAppointmentGroupByBookingTokenStatus",
  async ({ bookingToken }: { bookingToken: string }, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/appointment_groups/get_by_booking_token`,
        {
          params: {
            bookingToken,
            snakeCase: true,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAppointmentGroupByBookingToken = createAsyncThunk(
  "appointments/deleteAppointmentGroupByBookingTokenStatus",
  async ({ bookingToken }: { bookingToken: string }, thunkAPI) => {
    try {
      const { data } = await http.delete(
        `/v1/appointment_groups/destroy_by_booking_token`,
        {
          params: {
            bookingToken,
            snakeCase: true,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
