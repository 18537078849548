import React from "react";
import { Helmet } from "react-helmet";

export interface MetaDescriptorProps {
  title: string;
  description: string;
  link?: string;
}

export const MetaDescriptor: React.FC<MetaDescriptorProps> = ({
  title,
  description,
  link,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={link} />
    </Helmet>
  );
};
