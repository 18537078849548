"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Tip chip — https://app.subframe.com/feb895689ba0/library?component=Tip+chip_a4327237-e1b3-42f8-9a17-c5400aacda91
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import classNames from "classnames";

interface TipChipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  active?: boolean;
  className?: string;
  textClassName?: string;
}

const TipChipRoot = React.forwardRef<HTMLElement, TipChipRootProps>(
  function TipChipRoot(
    {
      text,
      active = false,
      className,
      textClassName,
      ...otherProps
    }: TipChipRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/a4327237 flex h-10 w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid border-neutral-500 bg-white px-2.5 py-2 hover:bg-success-100 active:border active:border-solid active:border-success-500 active:bg-success-300 mobile:hover:bg-success-300 focus-within:border focus-within:border-solid focus-within:border-success-500 focus-within:bg-success-300",
          { "border border-solid border-success-500 bg-success-300": active },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {text ? (
          <span
            className={classNames(
              "text-body-bold font-body-bold text-neutral-900",
              textClassName
            )}
          >
            {text}
          </span>
        ) : null}
      </div>
    );
  }
);

export const TipChip = TipChipRoot;
