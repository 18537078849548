"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Date chip — https://app.subframe.com/feb895689ba0/library?component=Date+chip_45e2c5d1-ec9f-4b1d-a7a4-41d1158d936f
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface DateChipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  days?: string;
  date?: string;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
}

const DateChipRoot = React.forwardRef<HTMLElement, DateChipRootProps>(
  function DateChipRoot(
    {
      days,
      date,
      disabled = false,
      selected = false,
      className,
      ...otherProps
    }: DateChipRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/45e2c5d1 flex cursor-pointer flex-col items-center gap-2",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {days ? (
          <span
            className={SubframeCore.twClassNames(
              "text-[14px] font-[400] leading-[20px] text-neutral-700 group-active/45e2c5d1:text-neutral-700",
              { "!text-neutral-300 !bg-transparent !cursor-default": disabled }
            )}
          >
            {days}
          </span>
        ) : null}
        <div
          className={SubframeCore.twClassNames(
            "flex h-8 w-8 flex-none flex-col items-center justify-center gap-4 rounded-[200px] group-hover/45e2c5d1:bg-neutral-100 mobile:hover:!bg-neutral-900 group-active/45e2c5d1:bg-neutral-900",
            {
              "bg-neutral-900": selected,
              "!text-neutral-300 !bg-transparent !cursor-default": disabled,
            }
          )}
        >
          {date ? (
            <span
              className={SubframeCore.twClassNames(
                "text-[18px] font-[500] leading-[24px] text-neutral-900 text-center group-active/45e2c5d1:text-white",
                {
                  "text-white": selected,
                  "!text-neutral-300 !bg-transparent": disabled,
                }
              )}
            >
              {date}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);

export const DateChip = DateChipRoot;
