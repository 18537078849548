import { getAccount } from "app/redux/accountSlice";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import paymentFormStyle from "app/order/components/paymentFormStyle";

export const useCreditCardPaymentModal = () => {
  const { nameKey, shopKey } = useParams();
  const dispatch = useDispatch();
  const cardFormRef: any = useRef(null);

  const init = async () => {
    const fetchedAccount = await dispatch(
      getAccount({ nameKey } as any) as any
    ).unwrap();

    if (fetchedAccount.acceptBlueTokenizationApi) {
      const initializeHostedTokenization = async () => {
        const hostedTokenization = new window.HostedTokenization(
          fetchedAccount.acceptBlueTokenizationApi
        );
        cardFormRef.current = await hostedTokenization.create("card-form");
        await cardFormRef.current.mount("#accept-blue-iframe");
        await cardFormRef.current.setStyles(paymentFormStyle);
      };

      initializeHostedTokenization();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return { cardFormRef };
};
