import { AsButton, LineItems, PoweredBy } from "app/subframe";
import * as React from "react";
import { ModalTypes } from "../../useOrder";

export interface InitialModalProps {
  setCurrentModal: (modal: ModalTypes) => void;
  subtotalCents: number;
  tipsCents: number;
}

export const InitialModal: React.FC<InitialModalProps> = ({
  setCurrentModal,
  subtotalCents,
  tipsCents,
}) => {
  return (
    <div className="flex flex-col gap-2 w-full p-2 py-4">
      <LineItems
        title="Total"
        value={`$${((subtotalCents + tipsCents) / 100).toFixed(2)}`}
      />
      <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        text="Continue"
        size="lg"
        loader={false}
        disabled={false}
        disabledSecondary={false}
        onClick={() => setCurrentModal(ModalTypes.CONFIRM_BOOKING)}
      />
      <PoweredBy text="Powered by" />
    </div>
  );
};
