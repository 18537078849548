import { AsButton } from "app/subframe";
import * as React from "react";

export interface BookTimeModalProps {
  updateCartWithTime: () => void;
}

export const BookTimeModal: React.FC<BookTimeModalProps> = ({
  updateCartWithTime,
}) => {
  return (
    <AsButton
      className="h-14 w-full flex-none"
      variant="default"
      text="Continue"
      size="lg"
      loader={false}
      disabled={false}
      disabledSecondary={false}
      onClick={() => updateCartWithTime()}
    />
  );
};
